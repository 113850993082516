.landing-box-4 {
    max-width: 1168px;
    padding-top: 80px;
    box-shadow: $landing-shadow;

    @media #{$large} {
        max-width: 960px;
    }

    @media #{$medium} {
        width: 100%;
        padding: 0 24px;
        box-shadow: none;
        gap: $grid-size * 6;
    }

    &__links {
        width: 432px;

        @media #{$medium} {
            width: 100%;
        }
    }
}
