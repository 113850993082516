.tab {
    padding-bottom: 4px;
    border-bottom: 2px solid #cbcbcb;
    cursor: pointer;
    transition: all 0.2s linear;

    &_active {
        border-bottom-color: #2f80ed;
        cursor: default;
    }

    &:hover {
        border-bottom-color: #76a4e1;
    }

    &:active {
        border-bottom-color: #1666d2;
    }

    &:focus {
        border-bottom-color: #4e97fa;
    }

    &:disabled {
        color: #cbcbcb;
        cursor: default;

        &:hover {
            border-bottom-color: #cbcbcb;
        }
    }
}
