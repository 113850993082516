.landing-box-12 {
    width: 100%;
    padding-top: 80px;
    background-color: var(--landing-color-darker-blue);
    color: var(--landing-color-text-white);
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;

    @media #{$medium} {
        line-height: 24px;
    }

    &__icon {
        width: 24px;
        height: 24px;
    }

    &__icon-logo {
        width: 79px;
        height: 36px;
    }

    &__social {
        @media #{$medium} {
            justify-content: start;
        }
    }

    &__icon-social {
        width: 32px;
        height: 32px;
    }

    &__icon-participantSK {
        width: 116px;
        height: 34px;
        background-image: url('../assets/images/sk.png');
        background-position: center;
        background-repeat: no-repeat;
    }

    &__icon-participantFasie {
        width: 122px;
        height: 60px;
        background-image: url('../assets/images/fasie-light.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    &__contacts {
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;

        @media #{$medium} {
            font-size: 16px;
            line-height: 28px;
        }
    }

    &__content {
        max-width: 1168px;
        padding-bottom: $grid-size * 20;

        @media #{$extra} {
            padding-right: 32px;
            padding-left: 32px;
        }

        @media #{$large} {
            width: 100%;
        }

        @media #{$medium} {
            gap: 80px;
            grid-template-columns: repeat(2, 1fr);
        }

        @media #{$small} {
            padding-bottom: 80px;
            grid-template-columns: repeat(1, 1fr);
        }
    }

    &__footer {
        width: 100%;
        height: $grid-size * 25;
        border-top: 1px solid var(--landing-color-dark-gray);

        &_copyright {
            text-align: center;

            @media #{$medium} {
                text-align: left;
            }
        }

        &_content {
            width: 100%;
            max-width: 1168px;
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            text-align: center;

            @media #{$extra} {
                padding-right: 32px;
                padding-left: 32px;
            }

            @media #{$medium} {
                display: flex;
                width: 100%;
                justify-content: space-around;
                padding: 0;
            }
        }
    }
}
