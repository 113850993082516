.chat {
    &__content {
        padding: 20px 100px;
    }

    .message-row {
        margin: 16px 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .message {
        position: relative;
        max-width: 70%;
        padding: 20px 24px;
        border-radius: 30px 30px 30px 0;
        background: #e0f2fc;
        color: #0d153f;
        font-size: 14px;

        &__tags {
            position: absolute;
            z-index: 1;
            bottom: -14px;
            left: 0;
            width: unset;
            padding: 2px;
            border-radius: 14px;
            background-color: #fff;
            box-shadow: 0 0 8px rgb(0 0 0 / 25%);
            transform: translateX(-50%);
        }

        &_right {
            right: 0;
            border-radius: 30px 30px 0;
            margin-left: auto;
            background: #efefef;

            .message__tags {
                right: 0;
                left: unset;
                transform: translateX(50%);
            }
        }

        &__title {
            line-height: 1;
        }

        &__body {
            margin-top: 8px;
        }

        &__tag {
            display: block;
            width: 24px;
            height: 24px;

            &_positive {
                color: #27ae60;
            }

            &_negative {
                color: #eb5757;
            }
        }
    }

    .name {
        font-weight: bold;
    }

    .time {
        margin-left: 15px;
        color: #898989;
        font-size: 14px;
    }

    .word {
        transition: background-color 300ms;

        &_active {
            background: #00f3;
        }

        &_unreliable {
            color: #f00;
        }
    }

    .end {
        margin: 20px 0;
        color: #828282;
        font-size: 16px;
        line-height: 1;
        text-align: center;
    }
}
