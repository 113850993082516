.text {
    &_wrap {
        white-space: pre-wrap;
    }

    &_center {
        text-align: center;
    }

    &_grey {
        color: #727172;
    }

    &_assertive {
        color: var(--landing-color-assertive);
    }

    &_small {
        font-size: 0.875em;
    }

    &_big {
        font-size: 1.5em;
    }

    &_black {
        color: #000;
    }

    &_right {
        text-align: right;
    }

    &_uppercase {
        text-transform: uppercase;
    }
}
