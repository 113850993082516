.files {
    display: grid;
    width: 1400px;
    min-height: calc(100vh - 84px);
    margin: 0 auto;
    background-color: #fff;
    grid-template: auto 1fr auto / 290px 1fr;
    grid-template-areas:
        'sidebar header'
        'sidebar body'
        'sidebar footer';

    @media #{$extra} {
        display: block;
        width: auto;
    }

    @media #{$large} {
        min-height: calc(100vh - 70px);
    }

    &__titles {
        padding: 0 0 20px;
        margin: 0;
        font-size: 14px;
        line-height: 16px;
        list-style: none;

        @media #{$extra} {
            display: none;
        }
    }

    hr {
        display: block;
        width: 100%;
        height: 0;
        border: none;
        border-bottom: 1px solid #c4dcf0;
        margin: 0;
        background: none;
    }

    &__title {
        padding: 0 9px;
        text-align: center;
    }

    &__title:nth-child(1) {
        flex-basis: 36px;
    }

    &__title:nth-child(2) {
        text-align: center;
    }

    &__title:nth-child(3) {
        flex-basis: 36px;
        padding: 0;
    }

    &__title:nth-child(4) {
        flex-basis: 170px;
    }

    &__title:nth-child(5) {
        flex-basis: 185px;
    }

    &__title:nth-child(6) {
        flex-basis: 185px;
    }

    &__title:nth-child(7) {
        flex-basis: 105px;
    }

    &__title:nth-child(8) {
        flex-basis: 105px;
    }

    &__actions {
        margin: 36px 0 36px 9px;

        @media #{$extra} {
            margin: 30px 0;
        }
    }

    &__sort-button {
        color: #1f79f3;
        font-size: 14px;
    }

    &__sort-selector {
        display: none;
        margin: 20px 0;

        @media #{$extra} {
            display: block;
        }
    }
}

.sidebar {
    backdrop-filter: blur(4px);
    background: rgb(0 0 0 / 70%);
    grid-area: sidebar;

    &-activator {
        width: 40px;
        height: 40px;
        margin-right: 6px;
        background-color: #1f79f3;
        color: #fff;
        font-size: 16px;
        text-align: center;

        &-place {
            display: none;
        }
    }

    &-close {
        position: absolute;
        top: 0;
        right: 0;
        display: none;
        width: calc(100% - 300px);
        height: 100%;

        @media #{$small} {
            top: 20px;
            right: 20px;
            width: unset;
            height: unset;
            padding: 10px;
        }

        i {
            position: absolute;
            top: 20px;
            left: 20px;
            color: #fff;
            font-size: 16px;

            @media #{$small} {
                position: relative;
                top: unset;
                left: unset;
                color: #000;
            }
        }
    }

    &-container {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #f7f8f9;

        &__content {
            position: sticky;
            top: 84px;
        }

        @media #{$extra} {
            width: 300px;
        }

        @media #{$small} {
            width: 100%;
            padding-top: 40px;
        }
    }

    @media #{$extra} {
        position: fixed;
        z-index: 100;
        top: 84px;
        left: 0;
        display: none;
        overflow: auto;
        width: 100%;
        height: calc(100vh - 84px);

        &_opened {
            display: block;
        }

        &-activator-place {
            display: block;
        }

        &-close {
            display: block;
        }
    }

    @media #{$large} {
        top: 70px;
        height: calc(100vh - 70px);
    }

    @media #{$medium} {
        width: 100%;
    }
}

.files-header {
    position: sticky;
    z-index: 1;
    top: 84px;
    padding: 40px 55px 0;
    background: #fff;
    grid-area: header;

    @media #{$extra} {
        padding: 20px 20px 0;
    }

    @media #{$large} {
        top: 70px;
    }
}

.files-body {
    overflow: auto;
    padding: 0 55px;
    grid-area: body;

    @media #{$extra} {
        padding: 0;
    }
}

.files-footer {
    padding: 40px 55px;
    grid-area: footer;
}

.file-dialog-header {
    padding: 0 55px;
}

.usage-metric {
    padding: 40px 30px 20px;

    &_minute {
        padding: 0 30px 20px;
    }

    &__title {
        font-size: 18px;
        font-weight: bold;
        line-height: 21px;
    }

    &__indicator {
        width: 100%;
        border-radius: 0;
        margin: 10px 0;

        &::-webkit-meter-bar {
            border-radius: 0;
            background: #fff;
        }

        &::-webkit-meter-optimum-value {
            border-radius: 0;
            background: #1f79f3;
        }

        &::-webkit-meter-even-less-good-value {
            border-radius: 0;
        }
    }

    &__info {
        color: #bdbdbd;
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
        line-height: 16px;
    }
}

.file-upload {
    padding: 30px;
}

.file-upload-text {
    padding: 0 30px;
    color: #555;
    font-size: 14px;
    line-height: 16px;
}

.file-chooser-counter {
    color: #969696;
    font-size: 14px;
    line-height: 16px;
}

.file-icon-chooser {
    font-size: 12px;
}

.file-hint {
    padding: 0 30px 30px;
    color: #bdbdbd;
    font-size: 14px;
    line-height: 16px;
}

.upload-settings {
    flex-basis: 100%;
}

.select-model-button {
    display: block;
    margin-top: 8px;
    color: #1f79f3;
    font-size: 14px;
    text-align: left;

    &:hover {
        text-decoration: underline;
    }
}

.files-search {
    position: relative;
    display: inline-block;
    width: 380px;
    font-size: 14px;

    @media #{$extra} {
        width: 100%;
    }

    &__input {
        width: 100%;
        padding: 10px 30px 10px 10px;
        border: 1px solid #bdbdbd;
        color: #4a4a4a;

        &:focus {
            border-color: #1f79f3;
            outline: none;
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        right: 10px;
        display: block;
        color: #bdbdbd;
        text-align: center;
        transform: translateY(-50%);
    }
}

.file-list-row {
    height: 64px;
    border-top: 1px solid #c0c3c5;
    color: #969696;
    font-size: 14px;
    line-height: 1;

    @media #{$medium} {
        position: relative;
        overflow: hidden;
        height: 100px;
        flex-wrap: wrap;
        padding: 0 30px 0 60px;
    }

    &:hover .checkbox__mark {
        opacity: 1;
    }

    &__filename {
        display: block;
        color: #4a4a4a;
        font-family: Roboto, sans-serif;
        font-size: 16px;
        line-height: 16px;
        text-decoration: none;
    }

    &__col {
        padding: 0 9px;
        text-align: center;

        @media #{$medium} {
            padding: 0;
            text-align: left;
        }

        &_menu {
            cursor: pointer;
            visibility: hidden;

            &:hover {
                color: #1f79f3;
            }
        }
    }

    &:hover &__col_menu {
        visibility: visible;
    }

    &__col:nth-child(1) {
        flex-basis: 36px;
        padding: 0;

        @media #{$extra} {
            flex-basis: 56px;
        }

        @media #{$medium} {
            position: absolute;
            top: 18%;
            left: 3%;
            width: 60px;
            height: 100px;
        }
    }

    &__col:nth-child(2) {
        min-width: 0;
        flex: 1 1 0;
        text-align: left;

        @media #{$extra} {
            min-width: 90px;
            padding-left: 0;
        }

        @media #{$medium} {
            flex-basis: 100%;
        }
    }

    &__col:nth-child(3) {
        flex-basis: 36px;

        @media #{$medium} {
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translate(50%, -50%);
        }
    }

    &__col:nth-child(4) {
        flex-basis: 170px;

        @media #{$medium} {
            margin: 10px 0;
        }
    }

    &__col:nth-child(5) {
        flex-basis: 185px;
    }

    &__col:nth-child(6) {
        flex-basis: 185px;
    }

    &__col:nth-child(7) {
        flex-basis: 105px;
    }

    &__col:nth-child(8) {
        flex-basis: 105px;
    }
}

.task-status {
    width: 18px;
    height: 18px;
    color: red;

    &_process {
        animation: spin 2s linear infinite;
        color: grey;
    }
}

@keyframes spin {
    100% {
        transform: rotate(1turn);
    }
}

.sort-icon-container {
    display: flex;
    width: fit-content;
    height: 14px;
    flex-direction: column;
    cursor: pointer;
    row-gap: 3px;

    &__icon {
        width: 8px;
        height: 8px;
        color: gray;

        &_active {
            color: #1f79f3;
        }
    }
}
