.popup {
    position: fixed;
    z-index: 10000000;
    right: 1rem;
    bottom: 1rem;
    display: flex;
    overflow: hidden;
    width: 450px;
    height: 80px;
    align-items: center;
    padding-left: 6px;
    animation: slide 500ms ease 0s 1 forwards;
    background-color: #fff;
    box-shadow: 0 0 8px #959595;
    cursor: default;
    font-size: 0.875rem;
    line-height: 1.2;
    text-align: left;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 6px;
        height: 100%;
        background: gray;
        content: '';
    }

    &__body {
        overflow: hidden;
        flex: 1 1 auto;
        padding: 0 20px;
        font-size: 14px;
    }

    &__title {
        color: #4f4f4f;
        font-weight: bold;
        line-height: 20px;
    }

    &__message {
        overflow: hidden;
        color: #959595;
        font-size: 14px;
        line-height: 20px;
        text-overflow: ellipsis;
        white-space: wrap;
    }

    &_error {
        &::before {
            background: red;
        }
    }

    &_message {
        &::before {
            background: green;
        }
    }

    &__close {
        display: flex;
        width: 50px;
        height: 100%;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        border-left: 1px solid #e0e0e0;
        color: #959595;
        cursor: pointer;
        font-size: 18px;

        &:hover {
            background: #f5f6f8;
            color: var(--color-primary);
        }
    }

    @keyframes slide {
        from {
            transform: translateY(calc(100% + 10px));
        }

        to {
            transform: translateY(0);
        }
    }
}
