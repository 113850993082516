.dialog {
    &__content {
        padding: 0 50px 50px;

        @media #{$large} {
            padding: 0 20px 20px;
        }
    }

    &_medium-fix {
        width: 50vw;
        max-width: 50vw;

        @media #{$large} {
            width: 80vw;
            max-width: 80vw;
        }
    }

    &_large-fix {
        width: 70vw;
        max-width: 70vw;
    }

    &_full-height {
        height: 100%;
        max-height: 100% !important;
    }

    &__footer {
        z-index: 1;
        display: flex;
        justify-content: flex-end;
        padding: 0.5rem 2.5rem;
        box-shadow: 0 0 10px rgba(224 224 224 / 40%);
        gap: 0.5rem;

        button {
            margin: unset;
        }
    }
}
