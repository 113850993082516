.control {
    position: relative;
    display: flex;
    width: 100%;
    min-width: 0;
    flex-flow: column nowrap;
    align-items: stretch;
    place-content: flex-start flex-start;

    &_auto-width {
        width: auto;
    }

    &_full-height {
        height: 100%;
    }

    &__title {
        overflow: hidden;
        color: var(--color-grey-title);
        font-size: 0.875rem;
        line-height: 1rem;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__title + input,
    &__title + textarea,
    &__title + .grid,
    &__title + .p-dropdown,
    &__title + .p-inputnumber,
    &__title + .p-multiselect,
    &__title + .p-colorpicker,
    &__title + .p-password,
    &__title + .p-calendar,
    &__title + .code-mirror-custom {
        margin-top: $grid-size * 2;
    }

    &__error {
        margin-top: $grid-size * 2;
        color: var(--color-assertive);
        font-size: 0.875rem;
        line-height: 1rem;
    }

    &__hint {
        margin-top: $grid-size * 2;
        // TODO: чекнуть цвет по стилям макета
        color: var(--color-grey-placeholder);
        font-size: 0.875rem;
        line-height: 1rem;
    }

    &__checkbox,
    &__radio {
        display: flex;
        min-width: 0;
        max-width: max-content;
        align-items: center;
    }

    &__checkbox-title,
    &__radio-title {
        overflow: hidden;
        margin-left: $grid-size * 2;
        color: var(--color-grey-title);
        cursor: pointer;
        font-size: 0.875rem;
        line-height: 1rem;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__textarea {
        height: 216px;
        resize: vertical;
        transition: none;

        &_small {
            height: 100px;
        }

        &_full-height {
            height: 100%;
        }
    }

    &__range {
        & input {
            max-width: 100%;
        }
    }

    &_vertical-checkbox {
        align-items: center;
        gap: 8px;
    }

    &_vertical-checkbox &__checkbox {
        margin: 12px 0;
    }
}

/* stylelint-disable-next-line selector-class-pattern */
.p-checkbox-disabled + .control__checkbox-title {
    color: var(--color-grey-disabled);
}

.p-radiobutton-disabled + .control__checkbox-title {
    color: var(--color-grey-disabled);
}
