.landing-box-10 {
    width: 100%;
    padding: 80px 0;
    background-color: var(--landing-color-dark-gray);
    color: var(--landing-color-text-white);
    gap: 56px;

    @media #{$large} {
        padding: 56px 24px;
    }

    @media #{$medium} {
        width: 100%;
        gap: 40px;
    }

    @media #{$small} {
        gap: 24px;
    }

    &__title {
        color: var(--landing-color-text-white);
    }

    &__subtitle {
        max-width: 670px;
        color: var(--landing-color-text-white);
    }

    // перенести в extensions
    &__inputs {
        &_textarea {
            width: 100%;
            max-width: 904px;

            @media #{$large} {
                min-height: 96px;
            }
        }

        &_grid {
            display: grid;
            width: fit-content;
            height: min-content;
            gap: 32px;
            grid-template-columns: repeat(2, minmax(200px, 436px));

            @media #{$medium} {
                width: 100%;
                grid-template-columns: repeat(1, 100%);
            }
        }

        &_checkboxes {
            max-width: 904px;
            gap: 16px;

            @media #{$medium} {
                padding: 16px 0 32px;
                gap: 32px;
            }

            @media #{$small} {
                padding: 24px 0 32px;
            }

            & span {
                color: var(--landing-color-text-white);
                font-size: 15px;
                font-weight: 400;
                line-height: 20px;

                @media #{$medium} {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16px;
                }
            }
        }

        textarea {
            max-width: 904px;
            max-height: 96px;
            border: 1px solid var(--landing-color-border-gray);
        }
    }
}
