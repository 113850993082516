.landing-box-1 {
    position: relative;
    width: 100%;
    height: 944px;
    background-color: var(--landing-color-darker-blue);
    background-image: url('../assets/images/landing-1.svg');
    background-position: right top 70px;
    background-repeat: no-repeat;
    background-size: 568px;
    color: var(--landing-color-text-white);

    @media #{$extra} {
        background-position: right bottom;
        background-size: 360px;
    }

    @media #{$large} {
        background-size: 340px;
    }

    @media #{$medium} {
        height: 783px;
        background-size: 340px;
    }

    @media #{$small} {
        justify-content: flex-end;
        padding-bottom: 80px;
        background-position: right top;
        background-size: 260px;
    }

    &__content {
        z-index: 1;
        display: flex;
        max-width: 1168px;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media #{$medium} {
            padding: 0 24px;
        }

        @media #{$small} {
            gap: 32px;
        }
    }

    &__text {
        max-width: 768px;
        text-align: center;

        @media #{$medium} {
            line-height: 32px;
        }
    }
}
