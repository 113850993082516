.landing-box-5 {
    max-width: 1168px;
    gap: 56px;

    @media #{$extra} {
        padding: 0 24px;
    }

    @media #{$large} {
        max-width: 960px;
    }

    @media #{$medium} {
        width: 100%;
        box-shadow: none;
    }

    @media #{$small} {
        gap: 24px;
    }

    &__card {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 40px 68px;
        box-shadow: $landing-shadow;

        @media #{$medium} {
            padding: 24px 16px;
        }
    }

    &__subtitle {
        max-width: 632px;
    }

    &__links {
        width: 1032px;

        @media #{$large} {
            overflow: auto;
            width: 100%;

            &::-webkit-scrollbar {
                display: none;
            }

            -ms-overflow-style: none;
            scrollbar-width: none;
        }
    }

    &__blocks {
        display: grid;
        gap: 32px;
        grid-template-columns: repeat(2, 1fr);

        @media #{$medium} {
            width: 100%;
            gap: 24px;
            grid-template-columns: none;
        }
    }

    &__speech-synthesis-block {
        display: grid;
        width: 100%;
        gap: 24px;

        @media #{$min-medium} {
            display: grid;
            gap: 32px;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);

            & :first-child {
                align-items: flex-start;
                grid-column: 1;
                grid-row: 1 / 4;
            }

            & :nth-child(2) {
                grid-column: 2;
                grid-row: 1;
            }

            & :nth-child(3) {
                grid-column: 2;
                grid-row: 2;
            }

            & :nth-child(4) {
                grid-column: 2;
                grid-row: 3;
            }
        }
    }
}
