.microphone-indicator {
    --value: 0;

    width: 30px;
    height: 30px;
    border-radius: 50%;
    background:
        radial-gradient(
            #f00 calc(70% * var(--value) - 1px),
            #f002 calc(70% * var(--value)),
            #f002
        );
}
