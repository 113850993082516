.landing {
    a {
        color: var(--landing-color-text-white);
        text-decoration: none;
    }

    .link {
        color: var(--landing-color-button);
    }

    p a {
        color: black;
    }

    color: var(--landing-color-text);
    gap: 304px;

    @media #{$large} {
        gap: 240px;
    }

    @media #{$medium} {
        gap: 200px;
    }

    &__link {
        min-width: 192px;
        padding-bottom: 8px;
        border-bottom: 2px solid var(--landing-color-lighter-gray);
        color: var(--landing-color-gray);
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
        text-align: center;

        &_default-size {
            font-size: 16px;
        }

        &_active {
            border-color: var(--landing-color-button);
            color: var(--landing-color-text);
        }

        @media #{$medium} {
            font-size: 16px;
            font-weight: 400;
            line-height: 32px;
        }
    }

    &__button {
        padding: 20px 60px;
        box-shadow: 0 10px 100px 0 #0b11311a;
        color: var(--landing-color-text-white);
        font-size: 16px;
        line-height: 24px;
        text-decoration: none;

        @media #{$medium} {
            padding: 16px 30px;
        }
    }

    &__header {
        font-size: 56px;
        font-weight: 400;
        line-height: 92px;
        text-align: center;

        @media #{$large} {
            font-size: 48px;
        }

        @media #{$medium} {
            font-size: 32px;
            line-height: 48px;
        }
    }

    &__subtitle {
        color: var(--landing-color-text-subtitle);
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
        text-align: center;

        @media #{$medium} {
            font-size: 18px;
            font-weight: 400;
            line-height: 32px;
            text-align: center;
        }
    }

    &__text {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.7;
        white-space: pre-wrap;

        @media #{$medium} {
            font-size: 18px;
            font-weight: 400;
            line-height: 30px;
        }
    }
}

.only-desktop {
    @media (width <= 1024px) {
        display: none;
    }
}

.not-desktop {
    @media (width >= 1023px) {
        display: none;
    }
}

.only-pad {
    @media (width >= 769px) {
        display: none;
    }
}

.not-pad {
    @media (width <= 768px) {
        display: none;
    }
}

.only-mobile {
    @media (width >= 480px) {
        display: none;
    }
}

.not-mobile {
    @media (width <= 480px) {
        display: none;
    }
}

.list {
    list-style: '– ' inside;

    li:not(:first-child) {
        margin-top: 24px;
    }

    &_small li:not(:first-child) {
        margin-top: 16px;
    }

    &_white li::marker {
        color: var(--landing-color-text-white);
    }
}
