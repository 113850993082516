.progress-overlay {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.progress-icon {
    width: 40px;
    height: 40px;
    color: $primaryColor;
    font-size: 40px;

    &_small {
        width: 1rem;
        height: 1rem;
        font-size: 1rem;
    }
}
