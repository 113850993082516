:root {
    --landing-color-text-white: #fff;
    --landing-color-text-subtitle: #505050;
    --landing-color-checkbox-label: #4f4f4f;
    --landing-color-text: #262626;
    --landing-color-text-dark-blue: #0b1131;
    --landing-color-text-gray: #a1a5af;
    --landing-color-text-box-8-1_3: #838696;
    --landing-color-text-box-8-2-2: #a4a8b2;
    --landing-color-white: #fff;
    --landing-color-darker-gray: #434c5f;
    --landing-color-dark-gray: #565d6f;
    --landing-color-gray: #727172;
    --landing-color-light-gray: #666;
    --landing-color-lighter-gray: #e8e8e8;
    --landing-color-lightest-gray: #0b11311a;
    --landing-color-accordeon-light-gray: #f7f8f9;
    --landing-color-carousel-button: #b3b3b3;
    --landing-color-darkest-blue: #15203c;
    --landing-color-darker-blue: #101a33;
    --landing-color-dark-blue: #3d465f;
    --landing-color-blue-card: #243050;
    --landing-color-blue: #5382ff;
    --landing-color-light-blue: #bacdff;
    --landing-color-border: #e0e0e0;
    --landing-color-border-gray: #a3a3a3;
    --landing-color-border-dark-gray: #424242;
    --landing-color-button: #00a1e4;
    --landing-color-button-hover: #0195d4;
    --landing-color-button-hover-outlined: #00a8f018;
    --landing-color-button-active: #0287c0;
    --landing-color-button-active-outlined: #00a8f033;
    --landing-color-assertive: #ef4444;
}

$landing-shadow: 0 10px 100px 0 #0b11311a;

$grid-size: 4px;

$small: '(max-width: 480px)';
$medium: '(max-width: 768px)';
$large: '(max-width: 1024px)';
$extra: '(max-width: 1400px)';
$min-medium: '(min-width: 768px)';
