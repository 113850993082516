.header {
    position: sticky;
    z-index: 1000;
    top: 0;
    width: 100%;
    height: 84px;
    background-color: var(--landing-color-darker-blue);

    &__item {
        width: min-content;
        white-space: nowrap;
    }

    &__icon {
        width: 48px;
        height: 48px;
    }

    &__burger {
        width: 36px;
        height: 36px;
    }

    &__dropdown-menu {
        @media #{$large} {
            position: fixed;
            top: 0;
            right: 0;
            width: 480px;
            flex-direction: column;
            padding: 24px;
            background: var(--landing-color-darkest-blue);
        }

        @media #{$medium} {
            left: 0;
            display: flex;
            width: 100%;
            height: 100%;
        }

        hr {
            width: 100%;
            height: 1px;
            border: none;
            margin: 24px 0;
            background-color: var(--landing-color-border);
        }
    }

    &__logo {
        width: 94px;
        min-width: 94px;
        height: 32px;
    }

    &__content {
        max-width: 1168px;
        flex: 0 0 100%;

        @media #{$extra} {
            width: 100%;
            padding: 0 6 * $grid-size;
        }
    }

    &_button {
        text-decoration: none;
    }

    &__link {
        color: white;
        text-decoration: none;

        &_active {
            color: var(--landing-color-button);
        }

        &_mobile {
            width: 100%;
            padding: 24px 0;
            text-align: center;
        }
    }
}

.user-info {
    &__title {
        color: white;
        font-size: 56px;
        font-weight: 400;
        line-height: 92px;
        text-align: center;
    }

    &__icon {
        width: 18px;
        height: 18px;
        line-height: 1;
    }

    &__input {
        display: flex;
        width: 640px;
        height: 48px;
        justify-content: space-between;
        padding: 12px;
        border: 1px solid var(--landing-color-border-dark-gray);
        color: white;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;

        @media #{$large} {
            width: 100%;
        }
    }

    &__info {
        &_panel {
            background: var(--landing-color-darkest-blue);

            span,
            button {
                padding: 12px;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: left;
            }

            .p-overlaypanel-content {
                display: flex;
                flex-direction: column;
                gap: 12px;
            }

            &::before,
            &::after {
                display: none;
            }
        }

        &_email {
            padding: 24px 0;
            color: var(--landing-color-border-gray);
            text-align: center;
            text-decoration: underline;
        }

        &_exit,
        &_dev {
            padding: 24px 0;
            color: var(--landing-color-text-white);
            font-size: 1rem;
            text-align: center;
        }
    }

    &__dialog {
        width: 1168px;
        max-width: 1168px;

        @media #{$extra} {
            width: 80%;
        }

        .p-dialog-header {
            background: var(--landing-color-darkest-blue);
        }

        .p-dialog-content {
            padding: 0 24px 80px;
            background: var(--landing-color-darkest-blue);
        }
    }
}
