.landing-box-3 {
    width: 100%;
    height: auto;
    padding: 80px 0;
    background-color: var(--landing-color-dark-gray);
    gap: 56px;

    @media #{$medium} {
        padding: 80px 24px;
        gap: 24px;
    }

    &__cards {
        @media #{$extra} {
            flex-wrap: nowrap;
            padding: 0 24px;
        }

        @media #{$medium} {
            flex-wrap: wrap;
            padding: 24px 0;
        }
    }

    &__card {
        width: 368px;
        height: 368px;
        padding: 56px 38px 34px;
        background-color: var(--landing-color-darker-gray);

        @media #{$large} {
            width: 307px;
        }

        @media #{$medium} {
            width: 100%;
            height: 336px;
        }

        &_texts {
            color: var(--landing-color-text-gray);
            text-align: center;
        }

        &_icon {
            width: 88px;
            height: 88px;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    &__header {
        color: var(--landing-color-text-white);
    }

    &__subtitle {
        max-width: 768px;
        color: var(--landing-color-text-white);
        text-align: center;
    }
}
