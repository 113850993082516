.landing-box-2 {
    gap: 56px;

    @media #{$extra} {
        padding: 0 24px;
    }

    @media #{$large} {
        gap: 32px;
    }

    @media #{$medium} {
        gap: 24px;
    }

    &__subtitle {
        max-width: 768px;
        color: var(--landing-color-text-subtitle);
    }

    &__cards {
        display: grid;
        width: min-content;
        max-width: 100%;
        height: min-content;
        align-items: start;
        gap: 32px;
        grid-template-columns: repeat(2, calc(50% - 16px));

        @media #{$large} {
            padding-top: 48px;
            gap: 20px;

            & > div {
                gap: 20px;
            }
        }

        @media (width <= 1050px) {
            width: 85%;
            grid-template-columns: repeat(1, 100%);
        }

        @media #{$medium} {
            width: 100%;
        }
    }

    &__card {
        width: 600px;
        max-width: 600px;
        background-color: white;
        box-shadow: $landing-shadow;

        @media #{$extra} {
            width: 100%;
            max-width: 100%;
            padding: 24px 0 24px 24px;
        }

        @media #{$medium} {
            padding: 24px 0 24px 16px;
        }

        &-text {
            padding: 40px 0 40px 40px;

            @media (width <= 500px) {
                padding: 24px 16px;
            }
        }

        &_reversed {
            &-text {
                padding: 40px 40px 40px 0;
            }

            @media #{$extra} {
                padding: 24px 24px 24px 0;
            }

            @media #{$medium} {
                padding: 24px 16px 24px 0;
            }

            @media (width <= 500px) {
                padding: 24px 16px;
            }
        }
    }

    &__card-title {
        color: var(--landing-color-text);
        font-size: 22px;
        font-weight: 700;
        line-height: 32px;
    }

    &__icon {
        min-width: 180px;
        min-height: 180px;
        fill: initial;

        @media #{$medium} {
            min-width: 140px;
            min-height: 140px;
        }

        @media (width <= 500px) {
            display: none;
        }
    }
}
