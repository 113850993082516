.admin {
    display: flex;
    width: 100%;
    max-width: 1400px;
    height: 100%;
    min-height: calc(100vh - 84px);
    flex-direction: column;
    align-items: center;
    padding: 0 55px;
    margin: 0 auto;
    background-color: white;
}

.admin__header {
    position: sticky;
    top: 84px;
    padding-top: 48px;
    background: #fff;
}

.admin-body,
.admin-footer {
    padding: 40px 55px;
}

.admin-login-button {
    max-width: inherit;
}

.admin-footer {
    margin-top: auto;
}

.user-list-row {
    display: flex;
    width: 100%;
    height: 64px;
    flex-wrap: nowrap;
    align-items: center;
    border-top: 1px solid #c0c3c5;
    color: #969696;
    font-size: 14px;
    line-height: 1;

    &__sort-button {
        color: #1f79f3;
        font-size: 14px;
    }

    &_header {
        border-top: none;
        font-weight: bold;
    }

    &__col {
        flex: 1 0 170px;

        &_login {
            min-width: 300px;
            max-width: 300px;
        }

        &_center {
            text-align: center;
        }
    }
}

.user-status {
    color: green;

    &::before {
        content: '✔';
    }

    &_deleted {
        color: red;

        &::before {
            content: '✖';
        }
    }
}

.export-icon {
    color: #969696;
    font-size: 1.5em;

    &:hover {
        color: #1f79f3;
    }
}

.user-history {
    &__year {
        display: block;
        margin: 40px 0 10px;
        color: #274874;
        font-size: 28px;
        font-weight: 300;
        text-align: center;

        &:first-child {
            margin-top: 10px;
        }
    }

    &__month {
        color: #888;
        font-size: 12px;
    }
}
