.documentation {
    display: flex;
    width: 100%;
    min-height: calc(100vh - 84px);
    flex-wrap: nowrap;
    justify-content: center;
    margin: 0 auto;
    background-color: white;

    ::-webkit-scrollbar {
        height: 3px;
    }

    ::-webkit-scrollbar-thumb {
        background: #c5c5c5;
    }

    ::-webkit-scrollbar-track {
        background: #e6e6e6;
    }

    &__text-wrapper {
        overflow: auto;
        height: calc(100vh - 84px);
        padding: 20px 30px 20px 80px;

        @media #{$large} {
            overflow: unset;
            height: unset;
            padding: unset;
        }
    }

    &__text-content {
        width: 700px;

        @media #{$extra} {
            width: unset;
            max-width: 700px;
        }
    }

    @media #{$large} {
        flex-direction: column;
        padding: 20px;
    }

    &__text {
        flex: 1 1 auto;
        color: #36393f;
        font-size: 16px;
        line-height: 2;
        word-break: break-word;

        h1 {
            font-size: 32px;
            font-weight: bold;
            line-height: 2;
        }

        h2 {
            font-size: 24px;
            font-weight: bold;
            line-height: 2;
        }

        h3 {
            font-size: 20px;
            font-weight: bold;
            line-height: 2;
        }

        h4 {
            font-size: 16px;
            font-weight: bold;
            line-height: 2;
            text-indent: 32px;
        }

        h5 {
            margin-top: 16px;
            font-size: 16px;
            line-height: 2;
        }

        * + h2,
        * + h3,
        * + h4 {
            margin-top: 24px;
        }

        h1 + h2,
        h2 + h3,
        h3 + h4 {
            margin-top: 0;
        }

        p {
            margin-top: 16px;
            text-indent: 32px;
        }

        h5 + p {
            margin-top: 0;
            text-indent: 0;
        }

        ul {
            padding: 0 0 0 32px;
            list-style: none;
        }

        li {
            position: relative;
            line-height: 2;

            &::before {
                width: 8px;
                padding-right: 24px;
                color: #1f79f3;
                content: '•';
            }
        }

        table {
            width: 100%;
            margin-top: 16px;

            th {
                min-width: 100px;
                background-color: var(--landing-color-accordeon-light-gray);
                font-weight: bold;
                vertical-align: middle;
                white-space: nowrap;
            }

            th,
            td {
                padding: 8px 16px;
                border: 1px solid var(--landing-color-border);
                font-size: 14px;
                line-height: 1.5;
                text-align: center;
                vertical-align: middle;
            }

            tbody tr:nth-child(2n) td {
                background-color: var(--landing-color-accordeon-light-gray);
            }
        }

        h5 + table {
            margin-top: 0;
        }

        code {
            display: block;
            width: 100%;
            padding: 16px 32px;
            margin-top: 16px;
            background-color: var(--landing-color-accordeon-light-gray);
            font-size: 16px;
            line-height: 1.5;
            white-space: pre-wrap;
        }
    }

    table.table-api {
        tbody tr td,
        thead tr th {
            text-align: left;
            word-break: keep-all;
        }
    }

    table.table-specifications {
        td:first-child,
        th:first-child {
            text-align: left;
        }
    }

    table.table-normalization {
        tbody:nth-child(2n) tr td {
            background-color: #fff;
        }

        tbody:nth-child(2n-1) tr td {
            background-color: var(--landing-color-accordeon-light-gray);
        }

        tbody tr:first-child td:first-child {
            text-align: left;
        }
    }

    &__menu {
        @media #{$large} {
            width: 100%;
            margin: 0 auto;
        }
    }
}

.documentation-menu {
    position: sticky;
    display: flex;
    width: 400px;
    min-width: 400px;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    list-style: none;

    &__wrapper {
        overflow: auto;
        height: calc(100vh - 84px);
        border-right: 1px solid gainsboro;

        @media #{$large} {
            overflow: unset;
            height: unset;
            border: unset;
        }
    }

    @media #{$large} {
        width: 100%;
        margin: 0 auto;
    }

    &__item {
        display: flex;
        align-items: center;
        padding: 16px;
        color: #27282d;
        font-size: 16px;
        gap: 8px;
        text-decoration: none;
        transition: 0.2s;
        word-break: break-word;

        &_inner {
            padding: 16px 16px 16px 40px;
        }

        &:hover {
            background-color: #f2f2f2b8;
        }

        @media #{$large} {
            font-size: 14px;
        }
    }

    &__item_active {
        background-color: #f2f2f2;
    }
}

.api {
    line-height: 22px;

    & pre {
        overflow: auto;
    }

    &__grid-template {
        grid-template-columns: 1fr 2fr;
    }

    &__subtitle {
        margin-top: 30px;
        font-size: 18px;
        font-weight: bold;
    }

    &__method-type {
        width: 70px;
        min-width: 70px;
        padding: 10px 15px;
        border-radius: 16px;
        background-color: #ffead1;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
        word-break: normal;

        &_get {
            background-color: #ffead1;
        }

        &_post {
            background-color: #daf4d6;
        }

        &_grpc {
            background-color: #c9c9c9;
        }

        &_delete {
            background-color: #f4b7b7;
        }

        &_patch {
            background-color: #cae4e9;
        }
    }

    &__parameter {
        font-style: italic;
    }

    &__parameter-description {
        text-align: justify;
    }

    &__accordion {
        width: 100%;
        border: 1px solid #dddfe0;
        border-radius: 16px;
        cursor: pointer;
        transition: 1s;
    }

    &__accordion-icon {
        font-size: 22px;
    }

    &__accordion-header {
        padding: 30px;
        font-size: 18px;

        &:hover .api__accordion-icon {
            color: #2f80ed;
        }
    }

    &__accordion-body {
        padding: 30px;
        padding-top: 0;
        cursor: default;
        font-size: 14px;
    }

    &__accordion-response {
        overflow: auto;
        font-family: monospace;
        font-size: 14px;
    }

    &__param {
        color: gray;
        font-size: 12px;
        font-style: italic;
        line-height: 12px;
    }

    &__type {
        color: gray;
        font-size: 14px;
    }

    &__code {
        color: #00a127;

        &_error {
            color: #e31c1c;
        }
    }
}

.copy-button {
    width: 25px;
    height: 25px;
    fill: #575757;

    :hover {
        fill: #2f80ed;
    }

    & svg {
        width: 25px;
        height: 25px;
    }
}

.open-in-github {
    padding: 10px 20px;
    border: 1px solid black;
    border-radius: 10px;
    background-color: #001435;
    color: #fff;
    text-decoration: none;

    &:hover {
        opacity: 0.9;
    }

    & i {
        font-size: 22px;
    }
}
