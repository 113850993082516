.landing-box-8 {
    max-width: 1168px;
    gap: 56px;

    @media #{$extra} {
        padding: 0 24px;
    }

    @media #{$large} {
        max-width: 960px;
    }

    @media #{$medium} {
        width: 100%;
    }

    @media #{$small} {
        gap: 24px;
    }

    &__cards {
        display: flex;
        width: 100%;
        height: min-content;
        flex-wrap: nowrap;
        justify-content: center;
        gap: $grid-size * 4;

        @media #{$medium} {
            flex-wrap: wrap;
        }
    }

    &__subtitle {
        max-width: 832px;
        color: var(--landing-color-text-subtitle);
        text-align: center;
    }

    &__card-title {
        color: var(--landing-color-text-dark-blue);
        font-size: 22px;
        font-weight: 700;
        line-height: 32px;
        text-align: center;
    }

    &__card-icon {
        width: 88px;
        height: 88px;
    }

    &__card-texts {
        color: var(--landing-color-text-box-8-1_3);
    }

    &__card {
        max-width: 375px;
        padding: 56px 22px;
        box-shadow: $landing-shadow;

        @media #{$large} {
            max-width: 306px;
        }

        @media #{$medium} {
            max-width: 100%;
        }

        &_1 {
            & .landing-box-8__card-title {
                color: white;
            }

            & .landing-box-8__card-texts {
                color: var(--landing-color-text-box-8-2-2);
            }

            background-color: var(--landing-color-darker-gray);
            color: #a8c0ff;
        }
    }
}
