.landing-box-11 {
    max-width: 1344px;

    @media #{$extra} {
        max-width: 100%;
        gap: 24px;
    }

    &__carousel {
        .p-carousel-content {
            .p-carousel-container {
                width: 1344px;
                max-width: 1344px;

                @media #{$extra} {
                    width: 95vw;
                    max-width: 95vw;
                }

                .p-carousel-item {
                    display: flex;
                    justify-content: center;
                    cursor: pointer;
                    filter: grayscale(1) brightness(0.8);
                    transition: all 0.3s;

                    &:hover {
                        filter: none;
                    }
                }
            }

            .p-carousel-indicators .p-carousel-indicator {
                button {
                    height: 8px;
                    border-radius: 4px;
                    background-color: var(--landing-color-carousel-button);
                    box-shadow: none;

                    &:hover {
                        background-color: var(--landing-color-button-hover);
                    }
                }

                &.p-highlight {
                    button {
                        width: 80px;
                        background-color: var(--landing-color-carousel-button);
                    }
                }
            }
        }

        &_icon {
            width: 100px;
        }
    }
}
