.landing-box-9 {
    max-width: 1168px;

    @media #{$extra} {
        padding: 0 24px;
    }

    @media #{$large} {
        max-width: 960px;
    }

    @media #{$medium} {
        width: 100%;
    }

    @media #{$small} {
        gap: 24px;
    }

    &__link {
        width: 344px;
        @media #{$medium} {
            width: 100%;
        }
    }

    &__footer-cards {
        @media #{$medium} {
            flex-wrap: wrap;
        }
    }

    &__footer-card {
        height: 272px;
        padding: 56px 68px 40px;
        box-shadow: $landing-shadow;
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;

        @media #{$large} {
            height: 300px;
            padding: 30px 68px 40px;
        }

        @media #{$medium} {
            align-items: center;
        }

        &_gray {
            background-color: var(--landing-color-dark-gray);
        }
    }

    &__footer-card-text {
        color: var(--landing-color-text-white);
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;

        @media #{$medium} {
            width: 100%;
            text-align: center;
        }
    }

    &__footer-card-text-small {
        color: var(--landing-color-text-white);
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;

        @media #{$medium} {
            width: 100%;
            text-align: center;
        }
    }

    &__card {
        max-width: 1168px;
        padding: 56px 68px;
        box-shadow: $landing-shadow;
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;

        @media #{$large} {
            max-width: 960px;
        }

        @media #{$medium} {
            width: 100%;
            padding: 56px 16px;
        }

        &_small {
            color: var(--landing-color-gray);
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }
    }

    &__card-cost-title {
        max-width: 180px;
    }

    &__card-cost-row {
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
        text-align: left;

        @media #{$medium} {
            align-content: flex-end;
        }
    }

    &__card-content {
        @media #{$medium} {
            flex-wrap: wrap;
            gap: 40px;
        }
    }

    &__card-synthesis-costs {
        display: grid;
        grid-template-columns: repeat(2, auto);

        @media #{$medium} {
            display: flex;
        }
    }

    &__card-title {
        font-size: 22px;
        font-weight: 700;
        line-height: 32px;
        text-align: left;

        @media #{$medium} {
            justify-content: center;
            text-align: center;
        }
    }

    &__card-white-title {
        color: var(--landing-color-text-white);

        @media #{$medium} {
            width: 100%;
            text-align: center;
        }
    }

    &__block-header {
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
    }

    &__small {
        color: var(--landing-color-light-gray);
        font-size: 16px;
        line-height: 24px;
    }
}
