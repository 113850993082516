.landing-box-7 {
    max-width: 1168px;

    @media #{$large} {
        max-width: 960px;
    }

    @media #{$medium} {
        max-width: 100%;
    }

    &__accordeon {
        width: 100%;

        &.p-accordion {
            color: var(--landing-color-text);

            .p-accordion-header-link {
                border-right: none;
                border-bottom: none;
                border-left: none;
                background: transparent;
            }

            & > .p-accordion-tab {
                margin-bottom: 0;
            }

            & .p-accordion-content {
                padding: 24px 24px 24px 100px;
                padding-left: 100px;
                border: none;
                background: var(--landing-color-accordeon-light-gray);

                p {
                    max-width: 799px;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                }

                @media #{$medium} {
                    padding: 22px 22px 22px 80px;
                }
            }

            .p-accordion-header {
                &:not(.p-disabled) {
                    .p-accordion-header-link {
                        background: transparent;
                        box-shadow: none;
                        color: var(--landing-color-text);
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 24px;
                        outline: none;

                        @media #{$medium} {
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 24px;
                            text-align: left;
                        }

                        &:hover {
                            background-color: transparent;
                            color: var(--landing-color-text);
                        }
                    }

                    &.p-highlight {
                        .p-accordion-header-link {
                            background: var(--landing-color-accordeon-light-gray);
                            color: var(--landing-color-text);
                        }

                        &:hover {
                            background: var(--landing-color-accordeon-light-gray);
                            color: var(--landing-color-text);
                        }
                    }
                }
            }
        }
    }
}
