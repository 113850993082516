.page {
    position: relative;
    width: 100%;
    height: 100%;
    font-family: Roboto, serif;

    &__main {
        width: 100%;
        min-height: calc(100vh - 84px);

        &_no-header {
            min-height: 100vh;
        }

        &_files,
        &_synthesis {
            background: #565d6f;
        }

        &_scrolled {
            max-height: calc(100vh - 84px);
            overflow-y: scroll;
            scroll-behavior: smooth;
        }
    }
}

.register {
    right: 0;
    left: 0;
    margin: auto;
    background-color: var(--landing-color-darker-blue);
    color: #fff;

    &__row {
        min-height: calc(100vh - 84px);
        padding: 40px 0;

        @media #{$large} {
            padding: 20px;
        }
    }

    &__inputs {
        display: grid;
        width: 100%;
        gap: 32px;
        grid-template-columns: repeat(1, 1fr);

        @media #{$medium} {
            align-content: center;
            grid-template-columns: repeat(1, 1fr);
        }

        & .p-inputtext,
        .p-password {
            width: 100%;
            max-width: 400px;
        }

        &_checkbox {
            max-width: 400px;

            & > span {
                font-size: 14px;
                line-height: 18px;
                white-space: nowrap;
            }
        }
    }

    &__card {
        width: 100%;
        max-width: 720px;
        padding: 100px 0;
        border: 1px solid var(--landing-color-blue-card);
        background: var(--landing-color-darkest-blue);
        box-shadow: 4px 4px 50px rgb(0 0 0 / 25%);

        @media #{$large} {
            width: 100%;
            padding: 0;
            border: none;
            background: var(--landing-color-darker-blue);
            box-shadow: none;
        }
    }

    &__caption {
        font-size: 56px;
        font-weight: 300;
        line-height: 1;
        text-align: center;

        @media #{$large} {
            font-size: 32px;
        }

        @media #{$medium} {
            font-size: 32px;
        }
    }

    &__recover {
        color: var(--landing-color-button);
        font-size: 14px;
        text-decoration: none;

        &:hover {
            color: var(--landing-color-button-hover);
        }
    }

    &__recover-title {
        font-size: 20px;
        line-height: 1.5;
        text-align: center;
    }

    &__controls {
        padding: 0 20%;

        @media #{$large} {
            padding: 0 15%;
        }

        @media #{$medium} {
            padding: 0 10%;
        }
    }
}
