* {
    box-sizing: border-box;
}

b,
strong {
    font-weight: bold;
}

::-webkit-scrollbar {
    position: relative;
    width: 6px;
}

::-webkit-scrollbar-track {
    background: #ddd;
}

::-webkit-scrollbar-thumb {
    background: #1f79f3;
}

button {
    padding: 0;
    border: unset;
    background: unset;
    color: unset;
    cursor: pointer;
    text-align: left;

    &:focus {
        outline: unset;
    }

    &:disabled {
        cursor: unset;
    }
}

.text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

a.link {
    color: var(--landing-color-button);
    text-decoration: none;

    &:hover {
        color: var(--landing-color-button-hover);
    }

    &:active {
        color: var(--landing-color-button);
    }
}
